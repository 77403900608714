import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import cross from '../images/cross.svg';
import rocket from '../images/rocket.svg';
import whiteRocket from '../images/white-rocket.svg';
import newsletter from '../images/newsletter.svg';

class NewsletterModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReactModal 
                onRequestClose={() => this.props.changeNewsletterModalFlag(false)} 
                shouldCloseOnOverlayClick={true} 
                isOpen={this.props.newsletterModalFlag} 
                className={"generic-modal container-modal discover-blog animate__animated animate__fadeInDown animate__faster"}
                overlayClassName={"discover-blog-card-overlay"}
            >
                <div className='discover-blog-card'>
                    <img className= "closeModal" src={cross} onClick={() => this.props.changeNewsletterModalFlag(false)}/>
                    <div className='header-image'>
                        <img src={newsletter}/>
                    </div>
                    <h1>Join Our Newsletter!</h1>
                    <p>Stay updated with our latest articles and tech insights. Subscribe now!</p>
                    <input placeholder='Enter your email' value={this.props.input} onChange={(e)=>this.props.onChangeEmail(e)}></input>
                    {this.props.errorMessage !== "" ? <label>{this.props.errorMessage}</label> : null}
                    <button className='explore' onClick={() => this.props.changeNewsletterModalFlag(true)}>Subscribe <img src={whiteRocket}/></button>
                </div>
            </ReactModal>
        );
    }
}

NewsletterModal.propTypes = {
    newsletterModalFlag: PropTypes.bool.isRequired,
    changeNewsletterModalFlag: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    onChangeEmail: PropTypes.func.isRequired,
};

export default (NewsletterModal);