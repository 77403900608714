import React, { Component } from 'react';
import Footer from '../Footer';
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
  changeCurrentSection,
} from "../../actions/generalActions";
import user from '../images/user.svg';
import arrowLeft from '../images/arrow-left.svg';
import calendar from '../images/calendar.svg';
import reactCard from '../images/react-card.jpeg';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';

const texts = [
  "dotnet --version",
  "brew install ollama",
  `dotnet new console -n OllamaConsoleApp
cd OllamaConsoleApp`,
  `using System.Diagnostics;

class Program
{
    static void Main(string[] args)
    {
        var process = new Process
        {
            StartInfo = new ProcessStartInfo
            {
                FileName = "ollama",
                Arguments = "run llama3 'What is artificial intelligence?'",
                RedirectStandardOutput = true,
                UseShellExecute = false,
                CreateNoWindow = true
            }
        };

        process.Start();
        string result = process.StandardOutput.ReadToEnd();
        process.WaitForExit();

        Console.WriteLine(result);
    }
}
`,
  "dotnet run",
  `dotnet new webapi -n OllamaChatApp
cd OllamaChatApp`,
  `using Microsoft.AspNetCore.Mvc;
using System.Diagnostics;

namespace OllamaChatApp.Controllers
{
    [ApiController]
    [Route("api/[controller]")]
    public class ChatController : ControllerBase
    {
        [HttpPost]
        public ActionResult<string> Post([FromBody] string message)
        {
            var process = new Process
            {
                StartInfo = new ProcessStartInfo
                {
                    FileName = "ollama",
                    Arguments = $"run llama3 {message}",
                    RedirectStandardOutput = true,
                    UseShellExecute = false,
                    CreateNoWindow = true
                }
            };

            process.Start();
            string result = process.StandardOutput.ReadToEnd();
            process.WaitForExit();

            return Ok(result);
        }
    }
}`,
  `var builder = WebApplication.CreateBuilder(args);

// Add services for controllers
builder.Services.AddControllers();  // Ensures that controllers like ChatController are recognized

// Add services for Swagger (optional)
builder.Services.AddEndpointsApiExplorer();
builder.Services.AddSwaggerGen();

var app = builder.Build();

// Configure the application pipeline
if (app.Environment.IsDevelopment())
{
    app.UseSwagger();
    app.UseSwaggerUI();
}

app.UseHttpsRedirection();

// Map controllers (this enables endpoints like /api/chat to work)
app.MapControllers();

app.Run();
`,
  `dotnet new console -n OllamaScraperApp
cd OllamaScraperApp`,
  `class Program
{
    static async Task Main(string[] args)
    {
        string url = "https://ollama.com/library/llama3.2";
        using HttpClient client = new HttpClient();
        var response = await client.GetStringAsync(url);

        Console.WriteLine(response); // Display raw HTML for debugging
        // Here, you can use regex or an HTML parser to extract meaningful content
    }
}`,
  "dotnet add package HtmlAgilityPack",
  `using HtmlAgilityPack;

static string ExtractContent(string html)
{
    var doc = new HtmlDocument();
    doc.LoadHtml(html);

    // Extract content, e.g., all paragraphs
    var paragraphs = doc.DocumentNode.SelectNodes("//p");
    return string.Join('\n', paragraphs.Select(p => p.InnerText.Trim()));
}
`,
  `using System.Diagnostics;

static string AnalyzeContentWithOllama(string content)
{
    var process = new Process
    {
        StartInfo = new ProcessStartInfo
        {
            FileName = "ollama",
            Arguments = $"run llama3 'Summarize this: {content}'",
            RedirectStandardOutput = true,
            UseShellExecute = false,
            CreateNoWindow = true
        }
    };

    process.Start();
    string result = process.StandardOutput.ReadToEnd();
    process.WaitForExit();

    return result;
}`,
"curl -X POST \"http://localhost:5000/api/chat\" -H \"Content-Type: application/json\" -d \"What is artificial intelligence?\""
]

const delay = ms => new Promise(res => setTimeout(res, ms));

const llaveizquierda = "{";
const llavederecha = "}";
const corcheteizquierdo = "[";
const corchederecho = "]";
const parentesisizquierdo = "(";
const parentesisderecho = ")";
const menor = "<";
const mayor = ">";

class BlogArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codeCopied: 0
    }

    this.changeSection = this.changeSection.bind(this);
    this.onClickCopy = this.onClickCopy.bind(this);
  }

  changeSection() {
    this.props.changeCurrentSection("Blog");
    if (this.props.cookieUp)
      ReactGA4.send({ hitType: "pageview", title: "Blog", page: '/Blog' });
  }

  async onClickCopy(codeCopied, isJson) {
    if (isJson)
      navigator.clipboard.writeText(JSON.stringify(texts[codeCopied - 1], null, 2));
    else
      navigator.clipboard.writeText(texts[codeCopied - 1]);

    this.setState({ codeCopied: codeCopied });

    await delay(2000);

    this.setState({ codeCopied: 0 });
  }

  render() {
    let hash = window.location.hash;

    if (hash == null || hash == undefined || hash == "")
      window.location.pathname = "/blog";

    if (hash != "#install-and-run-ollama-net" && hash != "#build-chat-app-using-ollama" && hash != "#extract-and-analyze-web-content-using-ollama" && hash != "#bolt.new-vs-v0") {
      window.location.hash = "";
      window.location.pathname = "/blog";
    }

    return (
      <div className='blog-global-screen' onMouseOver={this.props.currentSection != "Blog" ? this.changeSection : () => { }}>
        {
          hash == "#install-and-run-ollama-net" ?
            <>
              <Helmet>
                <title>Getting started with Ollama in .NET</title>
                <meta name="description" content="Learn how to integrate Ollama, a powerful NLP tool, into a .NET console application. Step-by-step guide covering installation, configuration, and implementation." />
                <meta name="keywords" content="ollama, .NET, C#, AI, NLP, console application, language model, artificial intelligence" />
                <meta property="og:title" content="Getting started with Ollama in .NET" />
                <meta property="og:description" content="Learn how to integrate Ollama, a powerful NLP tool, into a .NET console application. Step-by-step guide covering installation, configuration, and implementation." />
                <meta property="og:type" content="article" />
                <meta property="article:published_time" content="2024-11-19" />
              </Helmet>
              <section class="article-section">
                <div className='article-container'>
                  <div className='article-header'>
                    <a href='/blog'>
                      <div className='back-button-container'>
                        <img src={arrowLeft} />
                        <label>Back to Articles</label>
                      </div>
                    </a>
                  </div>
                  <div className='article'>
                    <h1>Getting started with Ollama in .NET</h1>
                    <div className='author-date-container'>
                      {/* <div className='general-container'>
                        <img src={user} />
                        <label>Jonathan Confenti</label>
                      </div> */}
                      <div className='general-container'>
                        <img src={calendar} />
                        <label>November 19, 2024</label>
                      </div>
                    </div>
                    <div className='article-div'>
                      <p>
                        In this article, we'll walk you through how to integrate Ollama, a powerful natural language processing (NLP) tool, into a .NET console application.
                        You’ll learn how to install Ollama, configure it, and use it to implement advanced language model capabilities.
                      </p>
                      <h2>Prerequisites</h2>
                      <div className='step'>
                        <p className='mb-05'>
                          Before you begin, make sure you have the following installed:
                        </p>
                        <ul class="custom-bullets">
                          <li>.NET SDK (version 6.0 or higher)</li>
                          <li>Visual Studio or any editor of your choice</li>
                          <li>Ollama installed on your machine (detailed instructions below)</li>
                        </ul>
                        <p>You can use the following command to verify the installed version of .NET.</p>
                        <div className='bash'>
                          <div className='bash-header'>
                            <label>bash</label>
                            <Button onClick={() => this.onClickCopy(1, false)}>
                              <Icon className={this.state.codeCopied == 1 ? 'check' : ''} name={this.state.codeCopied == 1 ? 'check' : 'copy outline'} />
                              <p>{this.state.codeCopied == 1 ? ' Copied!' : 'Copy'}</p>
                            </Button>
                          </div>
                          <div className='bash-body'>
                            <p>dotnet --version</p>
                          </div>
                        </div>
                      </div>
                      <h3>Step 1: Install Ollama</h3>
                      <div className='step'>
                        <p>Ollama can be easily installed from the command line. Depending on your operating system, you can use the following commands:</p>
                        <h4>On macOS or Linux:</h4>
                        <div className='bash mt-10'>
                          <div className='bash-header'>
                            <label>bash</label>
                            <Button onClick={() => this.onClickCopy(2, false)}>
                              <Icon className={this.state.codeCopied == 2 ? 'check' : ''} name={this.state.codeCopied == 2 ? 'check' : 'copy outline'} />
                              <p>{this.state.codeCopied == 2 ? ' Copied!' : 'Copy'}</p>
                            </Button>
                          </div>
                          <div className='bash-body'>
                            <p>brew install ollama</p>
                          </div>
                        </div>
                        <h4>On Windows:</h4>
                        <ul class="custom-bullets numbers">
                          <li>Go to the <a href='https://ollama.com/' target='_blank'>Ollama page</a> and download the installer.</li>
                          <li>Follow the installation instructions.</li>
                        </ul>
                      </div>
                      <h3>Step 2: Create a New Console Application in .NET</h3>
                      <div className='step'>
                        <p>First, create a new console project in .NET by running the following in your terminal:</p>
                        <div className='bash'>
                          <div className='bash-header'>
                            <label>bash</label>
                            <Button onClick={() => this.onClickCopy(3, false)}>
                              <Icon className={this.state.codeCopied == 3 ? 'check' : ''} name={this.state.codeCopied == 3 ? 'check' : 'copy outline'} />
                              <p>{this.state.codeCopied == 3 ? ' Copied!' : 'Copy'}</p>
                            </Button>
                          </div>
                          <div className='bash-body'>
                            <p>dotnet new console -n OllamaConsoleApp</p>
                            <p><span className='orange'>cd</span> OllamaConsoleApp</p>
                          </div>
                        </div>
                        <p>This command generates a basic project structure. In the <span>Program.cs</span> file, you’ll see a default <span>Main()</span> that we’ll use shortly.</p>
                      </div>
                      <h3>Step 3: Set Up Ollama Usage</h3>
                      <div className='step'>
                        <p>Now that you have Ollama installed and a .NET project created, the next step is to integrate Ollama with .NET.
                          To do this, you’ll need to run Ollama commands from your console app using the <span>Process</span> class in .NET.
                        </p>
                        <p>Add the following code to the <span>Program.cs</span> file to invoke Ollama from the application:</p>
                        <div className='bash'>
                          <div className='bash-header'>
                            <label>csharp</label>
                            <Button onClick={() => this.onClickCopy(4, false)}>
                              <Icon className={this.state.codeCopied == 4 ? 'check' : ''} name={this.state.codeCopied == 4 ? 'check' : 'copy outline'} />
                              <p>{this.state.codeCopied == 4 ? ' Copied!' : 'Copy'}</p>
                            </Button>
                          </div>
                          <div className='bash-body'>
                            <p><span className='blue'>using</span> System.Diagnostics;</p>
                            <br></br>
                            <p><span className='blue'>class</span> <span className='red'>Program</span></p>
                            <p>{llaveizquierda}</p>
                            <p className='one-space'><span className='blue'>static void</span> <span className='red'>Main</span>(<span className='orange'>string</span>[] args)</p>
                            <p className='one-space'>{llaveizquierda}</p>
                            <p className='two-spaces'><span className='blue'>var</span> process = <span className='blue'>new</span> Process</p>
                            <p className='two-spaces'>{llaveizquierda}</p>
                            <p className='three-spaces'>StartInfo = <span className='blue'>new</span> ProcessStartInfo</p>
                            <p className='three-spaces'>{llaveizquierda}</p>
                            <p className='four-spaces'>FileName = <span className='green'>"ollama"</span>,</p>
                            <p className='four-spaces'>Arguments = <span className='green'>"run llama3 'What is artificial intelligence?'"</span>,</p>
                            <p className='four-spaces'>RedirectStandardOutput = <span className='blue'>true</span>,</p>
                            <p className='four-spaces'>UseShellExecute = <span className='blue'>false</span>,</p>
                            <p className='four-spaces'>CreateNoWindow = <span className='blue'>true</span></p>
                            <p className='three-spaces'>{llavederecha}</p>
                            <p className='two-spaces'>{llavederecha};</p>
                            <br></br>
                            <p className='two-spaces'>process.Start();</p>
                            <p className='two-spaces'><span className='orange'>string</span> result = process.StandardOutput.ReadToEnd();</p>
                            <p className='two-spaces'>process.WaitForExit();</p>
                            <p className='two-spaces'>Console.WriteLine(result);</p>
                            <p className='one-space'>{llavederecha}</p>
                            <p>{llavederecha}</p>
                          </div>
                        </div>
                        <p>This code runs a language model in Ollama and displays the result in the console.</p>
                      </div>
                      <h3>Step 4: Test Your Application</h3>
                      <div className='step'>
                        <p>Now that the code is set up, you can run your application to see the results. In the terminal, within the project directory, run:</p>
                        <div className='bash'>
                          <div className='bash-header'>
                            <label>bash</label>
                            <Button onClick={() => this.onClickCopy(5, false)}>
                              <Icon className={this.state.codeCopied == 5 ? 'check' : ''} name={this.state.codeCopied == 5 ? 'check' : 'copy outline'} />
                              <p>{this.state.codeCopied == 5 ? ' Copied!' : 'Copy'}</p>
                            </Button>
                          </div>
                          <div className='bash-body'>
                            <p>dotnet run</p>
                          </div>
                        </div>
                        <p>This should execute the Ollama model and display a response in the console.</p>
                      </div>
                      <h3>Demo Video</h3>
                      <div className='step'>
                        <p>To make the implementation clearer, here is a demonstration video showcasing the execution and results of the application:</p>
                        <ReactPlayer
                          url='../../getting-started-demo.mp4'
                          poster="../../portada.png"
                          controls={true}
                          width='100%'
                          height='auto'
                          style={{
                            borderRadius: '6px',
                            
                            background: 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)'
                          }}
                          config={{
                            file: {
                              attributes: {
                                poster: '../../portada.png'
                              }
                            }
                          }}
                        />
                      </div>
                      <h3>Conclusion</h3>
                      <div className='step'>
                        <p>
                          In this article, we covered the essential steps to integrate Ollama into a .NET console application, from installation to running the model.
                          You can now experiment with different commands and language models within your .NET project to add advanced capabilities to your applications.
                        </p>
                      </div>
                      <h3>Source Code</h3>
                      <div className='last-step'>
                        <p>
                          You can find the complete source code for this project on GitHub. Feel free to explore, clone, and modify it for your own use: <a href='https://github.com/coowise/OllamaConsoleApp' target='_blank'>GitHub Repository: OllamaConsoleApp</a>
                        </p>
                        <p>
                          This repository includes all the files and configurations used in this tutorial, making it easy for you to replicate and extend the application.
                        </p>
                      </div>
                    </div>
                    <div className='footer'>
                      <span>AI Development</span>
                    </div>
                  </div>
                </div>
              </section>
            </>
            : (hash == "#build-chat-app-using-ollama" ?
              <>
                <Helmet>
                  <title>How to Build a Chat App Using Ollama</title>
                  <meta name="description" content="Combine the power of ollama with web technologies to create an intelligent chat application from scratch." />
                  <meta name="keywords" content="ollama, .NET, C#, AI, NLP, console application, language model, artificial intelligence" />
                  <meta property="og:title" content="How to Build a Chat App Using Ollama" />
                  <meta property="og:description" content="Combine the power of ollama with web technologies to create an intelligent chat application from scratch." />
                  <meta property="og:type" content="article" />
                  <meta property="article:published_time" content="2024-11-19" />
                </Helmet>
                <section class="article-section">
                  <div className='article-container'>
                    <div className='article-header'>
                      <a href='/blog'>
                        <div className='back-button-container'>
                          <img src={arrowLeft} />
                          <label>Back to Articles</label>
                        </div>
                      </a>
                    </div>
                    <div className='article'>
                      <h1>How to Build a Chat App Using Ollama</h1>
                      <div className='author-date-container'>
                        {/*  <div className='general-container'>
                          <img src={user} />
                          <label>Jonathan Confenti</label>
                        </div> */}
                        <div className='general-container'>
                          <img src={calendar} />
                          <label>November 19, 2024</label>
                        </div>
                      </div>
                      <div className='article-div'>
                        <p>
                          In this article, we'll demonstrate how to create a simple chat application using Ollama in a .NET environment.
                          This app will expose an endpoint that can receive a message and respond like a chat interface.
                          We'll leverage Ollama to provide insightful responses to interesting queries.
                        </p>
                        <h2>Prerequisites</h2>
                        <div className='step'>
                          <p className='mb-05'>
                            Before you begin, make sure you have the following installed:
                          </p>
                          <ul class="custom-bullets">
                            <li>.NET SDK (version 6.0 or higher)</li>
                            <li>Visual Studio or any editor of your choice</li>
                            <li>Ollama installed on your machine. For detailed instructions, refer to our previous article, <a href='/article#install-and-run-ollama-net'>Getting started with Ollama in .NET</a></li>
                          </ul>
                          <p>You can use the following command to verify the installed version of .NET.</p>
                          <div className='bash'>
                            <div className='bash-header'>
                              <label>bash</label>
                              <Button onClick={() => this.onClickCopy(1, false)}>
                                <Icon className={this.state.codeCopied == 1 ? 'check' : ''} name={this.state.codeCopied == 1 ? 'check' : 'copy outline'} />
                                <p>{this.state.codeCopied == 1 ? ' Copied!' : 'Copy'}</p>
                              </Button>
                            </div>
                            <div className='bash-body'>
                              <p>dotnet --version</p>
                            </div>
                          </div>
                        </div>
                        <h3>Step 1: Create a New Web API Project</h3>
                        <div className='step'>
                          <p>We will start by creating a new ASP.NET Core Web API project. Open your terminal and run:</p>
                          <div className='bash mt-10'>
                            <div className='bash-header'>
                              <label>bash</label>
                              <Button onClick={() => this.onClickCopy(6, false)}>
                                <Icon className={this.state.codeCopied == 6 ? 'check' : ''} name={this.state.codeCopied == 6 ? 'check' : 'copy outline'} />
                                <p>{this.state.codeCopied == 6 ? ' Copied!' : 'Copy'}</p>
                              </Button>
                            </div>
                            <div className='bash-body'>
                              <p>dotnet new webapi -n OllamaChatApp</p>
                              <p><span className='orange'>cd</span> OllamaChatApp</p>
                            </div>
                          </div>
                          <p>This command generates a new web API project.</p>
                        </div>
                        <h3>Step 2: Set Up the Chat Controller</h3>
                        <div className='step'>
                          <p>
                            Create a new controller named <span>ChatController.cs</span> inside the <span>Controllers</span> folder.
                            This controller will handle incoming chat messages and interact with Ollama.
                          </p>
                          <p>
                            Add the following code to <span>ChatController.cs</span>:
                          </p>
                          <div className='bash'>
                            <div className='bash-header'>
                              <label>bash</label>
                              <Button onClick={() => this.onClickCopy(7, false)}>
                                <Icon className={this.state.codeCopied == 7 ? 'check' : ''} name={this.state.codeCopied == 7 ? 'check' : 'copy outline'} />
                                <p>{this.state.codeCopied == 7 ? ' Copied!' : 'Copy'}</p>
                              </Button>
                            </div>
                            <div className='bash-body'>
                              <p><span className='blue'>using</span> Microsoft.AspNetCore.Mvc;</p>
                              <p><span className='blue'>using</span> System.Diagnostics;</p>
                              <br></br>
                              <p><span className='blue'>namespace</span> <span className='red'>OllamaChatApp.Controllers</span></p>
                              <p>{llaveizquierda}</p>
                              <p className='one-space'>{corcheteizquierdo}<span className='grey'>ApiController</span>{corchederecho}</p>
                              <p className='one-space'>{corcheteizquierdo}<span className='grey'>Route{parentesisizquierdo}</span><span className='green'>"api/[controller]"</span><span className='grey'>{parentesisderecho}</span>{corchederecho}</p>
                              <p className='one-space'><span className='blue'>public class</span> <span className='red'>ChatController</span> : <span className='red'>ControllerBase</span></p>
                              <p className='one-space'>{llaveizquierda}</p>
                              <p className='two-spaces'>{corcheteizquierdo}<span className='grey'>HttpPost</span>{corchederecho}</p>
                              <p className='two-spaces'><span className='blue'>public</span> ActionResult{menor}<span className='orange'>string</span>{mayor} <span className='red'>Post</span>{parentesisizquierdo}[FromBody] <span className='orange'>string</span> message{parentesisderecho}</p>
                              <p className='two-spaces'>{llaveizquierda}</p>
                              <p className='three-spaces'><span className='blue'>var</span> process = <span className='blue'>new</span> Process</p>
                              <p className='three-spaces'>{llaveizquierda}</p>
                              <p className='four-spaces'>StartInfo = <span className='blue'>new</span> ProcessStartInfo</p>
                              <p className='four-spaces'>{llaveizquierda}</p>
                              <p className='five-spaces'>FileName = <span className='green'>"ollama"</span>,</p>
                              <p className='five-spaces'>Arguments = <span className='green'>$"run llama3 {llaveizquierda}message{llavederecha}"</span>,</p>
                              <p className='five-spaces'>RedirectStandardOutput = <span className='blue'>true</span>,</p>
                              <p className='five-spaces'>UseShellExecute = <span className='blue'>false</span>,</p>
                              <p className='five-spaces'>CreateNoWindow = <span className='blue'>true</span></p>
                              <p className='four-spaces'>{llavederecha}</p>
                              <p className='three-spaces'>{llavederecha};</p>
                              <br></br>
                              <p className='three-spaces'>process.Start();</p>
                              <p className='three-spaces'><span className='orange'>string</span> result = process.StandardOutput.ReadToEnd();</p>
                              <p className='three-spaces'>process.WaitForExit();</p>
                              <br></br>
                              <p className='three-spaces'><span className='blue'>return</span> Ok(result);</p>
                              <p className='two-spaces'>{llavederecha}</p>
                              <p className='one-space'>{llavederecha}</p>
                              <p>{llavederecha}</p>
                            </div>
                          </div>
                          <p>This code defines a POST endpoint that receives a message, runs it through Ollama, and returns the response.</p>
                        </div>
                        <h3>Step 3: Configure the API to Run</h3>
                        <div className='step'>
                          <p>
                            Make sure the application is configured to run. Open <span>Program.cs</span> and replace it with the following code:
                          </p>
                          <div className='bash'>
                            <div className='bash-header'>
                              <label>csharp</label>
                              <Button onClick={() => this.onClickCopy(8, false)}>
                                <Icon className={this.state.codeCopied == 8 ? 'check' : ''} name={this.state.codeCopied == 8 ? 'check' : 'copy outline'} />
                                <p>{this.state.codeCopied == 8 ? ' Copied!' : 'Copy'}</p>
                              </Button>
                            </div>
                            <div className='bash-body'>
                              <p><span className='blue'>var</span> builder = WebApplication.CreateBuilder(args);</p>
                              <br></br>
                              <p><span className='grey'>// Add services for controllers</span></p>
                              <p>builder.Services.AddControllers(); <span className='grey'>// Ensures that controllers like ChatController are recognized</span></p>
                              <br></br>
                              <p><span className='grey'>// Add services for Swagger (optional)</span></p>
                              <p>builder.Services.AddEndpointsApiExplorer();</p>
                              <p>builder.Services.AddSwaggerGen();</p>
                              <br></br>
                              <p><span className='blue'>var</span> app = builder.Build();</p>
                              <br></br>
                              <p><span className='grey'>// Configure the application pipeline</span></p>
                              <p><span className='blue'>if</span> (app.Environment.IsDevelopment())</p>
                              <p>{llaveizquierda}</p>
                              <p className='one-space'>app.UseSwagger();</p>
                              <p className='one-space'>app.UseSwaggerUI();</p>
                              <p>{llavederecha}</p>
                              <br></br>
                              <p>app.UseHttpsRedirection();</p>
                              <br></br>
                              <p><span className='grey'>// Map controllers (this enables endpoints like /api/chat to work)</span></p>
                              <p>app.MapControllers();</p>
                              <br></br>
                              <p>app.Run();</p>
                            </div>
                          </div>
                          <p>This code runs a language model in Ollama and displays the result in the console.</p>
                        </div>
                        <h3>Step 4: Test Your Chat Application</h3>
                        <div className='step'>
                          <p>Run your application using the following command in the terminal:</p>
                          <div className='bash'>
                            <div className='bash-header'>
                              <label>bash</label>
                              <Button onClick={() => this.onClickCopy(5, false)}>
                                <Icon className={this.state.codeCopied == 5 ? 'check' : ''} name={this.state.codeCopied == 5 ? 'check' : 'copy outline'} />
                                <p>{this.state.codeCopied == 5 ? ' Copied!' : 'Copy'}</p>
                              </Button>
                            </div>
                            <div className='bash-body'>
                              <p>dotnet run</p>
                            </div>
                          </div>
                          <p>You can test the endpoint using a tool like Postman or cURL. Here’s an example of how to send a POST request using cURL:</p>
                          <div className='bash'>
                            <div className='bash-header'>
                              <label>bash</label>
                              <Button onClick={() => this.onClickCopy(14, false)}>
                                <Icon className={this.state.codeCopied == 14 ? 'check' : ''} name={this.state.codeCopied == 14 ? 'check' : 'copy outline'} />
                                <p>{this.state.codeCopied == 14 ? ' Copied!' : 'Copy'}</p>
                              </Button>
                            </div>
                            <div className='bash-body'>
                              <p>curl -X POST <span className='green'>"http://localhost:5000/api/chat"</span> -H <span className='green'>"Content-Type: application/json"</span> -d <span className='green'>"\"What is artificial intelligence?\""</span></p>
                            </div>
                          </div>
                          <p>You should receive a response from Ollama based on your query.</p>
                        </div>
                        <h3>Step 5: Improve Your Chat App</h3>
                        <div className='step'>
                          <p>You can enhance your chat application by:</p>
                          <ul class="custom-bullets">
                            <li>Adding a simple front-end using HTML and JavaScript to interact with the API.</li>
                            <li>Implementing user session management to keep track of conversations.</li>
                            <li>Introducing more complex queries and responses, utilizing Ollama’s capabilities fully.</li>
                          </ul>
                        </div>
                        <h3>Demo Video</h3>
                        <div className='step'>
                          <p>To make the implementation clearer, here is a demonstration video showcasing the execution and results of the application:</p>
                          <ReactPlayer
                            url='../../build-a-chat-app.mp4'
                            poster="../../portada2.png"
                            controls={true}
                            width='100%'
                            height='auto'
                            style={{
                              borderRadius: '6px',
                              background: 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)'
                            }}
                            config={{
                              file: {
                                attributes: {
                                  poster: '../../portada2.png'
                                }
                              }
                            }}
                          />
                        </div>
                        <h3>Conclusion</h3>
                        <div className='step'>
                          <p>
                            In this article, we’ve built a simple chat application using Ollama in a .NET environment.
                            You now have a foundation to build upon, with the ability to enhance functionality and create a richer user experience.
                          </p>
                        </div>
                        <h3>Source Code</h3>
                        <div className='last-step'>
                          <p>
                            You can find the complete source code for this project on GitHub. Feel free to explore, clone, and modify it for your own use: <a href='https://github.com/coowise/OllamaChatApp' target='_blank'>GitHub Repository: OllamaChatApp</a>
                          </p>
                          <p>
                            This repository includes all the files and configurations used in this tutorial, making it easy for you to replicate and extend the application.
                          </p>
                        </div>
                      </div>
                      <div className='footer'>
                        <span>AI Development</span>
                      </div>
                    </div>
                  </div>
                </section>
              </>
              : (hash == "#extract-and-analyze-web-content-using-ollama" ?
                <>
                  <Helmet>
                    <title>Building a Web Scraper with .NET and Analyzing Content Using Ollama</title>
                    <meta name="description" content="Learn how to build a .NET-based web scraper and integrate it with Ollama for powerful content analysis." />
                    <meta name="keywords" content="ollama, .NET, C#, AI, NLP, console application, language model, artificial intelligence" />
                    <meta property="og:title" content="Building a Web Scraper with .NET and Analyzing Content Using Ollama" />
                    <meta property="og:description" content="Learn how to build a .NET-based web scraper and integrate it with Ollama for powerful content analysis." />
                    <meta property="og:type" content="article" />
                    <meta property="article:published_time" content="2024-11-19" />
                  </Helmet>
                  <section class="article-section">
                    <div className='article-container'>
                      <div className='article-header'>
                        <a href='/blog'>
                          <div className='back-button-container'>
                            <img src={arrowLeft} />
                            <label>Back to Articles</label>
                          </div>
                        </a>
                      </div>
                      <div className='article'>
                        <h1>Building a Web Scraper with .NET and Analyzing Content Using Ollama</h1>
                        <div className='author-date-container'>
                          {/* <div className='general-container'>
                            <img src={user} />
                            <label>Jonathan Confenti</label>
                          </div> */}
                          <div className='general-container'>
                            <img src={calendar} />
                            <label>November 19, 2024</label>
                          </div>
                        </div>
                        <div className='article-div'>
                          <p>
                            In this article, we’ll cover how to build a simple web scraper in .NET and integrate Ollama to process and analyze the extracted content.
                            This combination of tools enables us to gather real-time data and use a language model to gain insights, making it ideal for applications
                            like summarization, content analysis, or sentiment analysis.
                          </p>
                          <h2>Prerequisites</h2>
                          <div className='step'>
                            <p className='mb-05'>
                              Before you begin, make sure you have the following installed:
                            </p>
                            <ul class="custom-bullets">
                              <li>.NET SDK (version 6.0 or higher)</li>
                              <li>Visual Studio or any editor of your choice</li>
                              <li>Ollama installed on your machine. For detailed instructions, refer to our previous article, <a href='/article#install-and-run-ollama-net'>Getting started with Ollama in .NET</a></li>
                            </ul>
                            <p>You can use the following command to verify the installed version of .NET.</p>
                            <div className='bash'>
                              <div className='bash-header'>
                                <label>bash</label>
                                <Button onClick={() => this.onClickCopy(1, false)}>
                                  <Icon className={this.state.codeCopied == 1 ? 'check' : ''} name={this.state.codeCopied == 1 ? 'check' : 'copy outline'} />
                                  <p>{this.state.codeCopied == 1 ? ' Copied!' : 'Copy'}</p>
                                </Button>
                              </div>
                              <div className='bash-body'>
                                <p>dotnet --version</p>
                              </div>
                            </div>
                          </div>
                          <h3>Step 1: Setting Up a Simple Web Scraper in .NET</h3>
                          <div className='step'>
                            <p>
                              We’ll start by building a basic web scraper using .NET’s <span>HttpClient</span>.
                              This scraper will fetch content from a web page and extract relevant information.
                            </p>
                            <p>Create a new .NET console application:</p>
                            <div className='bash mt-10'>
                              <div className='bash-header'>
                                <label>bash</label>
                                <Button onClick={() => this.onClickCopy(9, false)}>
                                  <Icon className={this.state.codeCopied == 9 ? 'check' : ''} name={this.state.codeCopied == 9 ? 'check' : 'copy outline'} />
                                  <p>{this.state.codeCopied == 9 ? ' Copied!' : 'Copy'}</p>
                                </Button>
                              </div>
                              <div className='bash-body'>
                                <p>dotnet new console -n OllamaScraperApp</p>
                                <p><span className='orange'>cd</span> OllamaScraperApp</p>
                              </div>
                            </div>
                            <p>Inside the <span>Program.cs</span> file, add the following code to fetch content from a web page:</p>
                            <div className='bash'>
                              <div className='bash-header'>
                                <label>bash</label>
                                <Button onClick={() => this.onClickCopy(10, false)}>
                                  <Icon className={this.state.codeCopied == 10 ? 'check' : ''} name={this.state.codeCopied == 10 ? 'check' : 'copy outline'} />
                                  <p>{this.state.codeCopied == 10 ? ' Copied!' : 'Copy'}</p>
                                </Button>
                              </div>
                              <div className='bash-body'>
                                <p><span className='blue'>class </span> <span className='red'>Program</span></p>
                                <p>{llaveizquierda}</p>
                                <p className='one-space'><span className='blue'>static async</span> Task <span className='red'>Main</span>{parentesisizquierdo}<span className='orange'>string</span>[] args{llavederecha}</p>
                                <p className='one-space'>{llaveizquierda}</p>
                                <p className='two-spaces'><span className='orange'>string</span> url = <span className='green'>"https://ollama.com/library/llama3.2"</span>;</p>
                                <p className='two-spaces'><span className='blue'>using</span> HttpClient client = <span className='blue'>new</span> HttpClient();</p>
                                <p className='two-spaces'><span className='blue'>var</span> response = <span className='blue'>await</span> client.GetStringAsync(url);</p>
                                <br></br>
                                <p className='two-spaces'>Console.WriteLine(response); <span className='grey'>// Display raw HTML for debugging</span></p>
                                <p className='two-spaces'><span className='grey'>// Here, you can use regex or an HTML parser to extract meaningful content</span></p>
                                <p className='one-space'>{llavederecha}</p>
                                <p>{llavederecha}</p>
                              </div>
                            </div>
                          </div>
                          <h3>Step 2: Extract Meaningful Content</h3>
                          <div className='step'>
                            <p>
                              Once you have the page’s HTML, parse it to extract relevant data.
                              For a simple approach, you can use regex, but for more complex scenarios, consider using a library like HtmlAgilityPack.
                            </p>
                            <p>Install HtmlAgilityPack by running:</p>
                            <div className='bash'>
                              <div className='bash-header'>
                                <label>bash</label>
                                <Button onClick={() => this.onClickCopy(11, false)}>
                                  <Icon className={this.state.codeCopied == 11 ? 'check' : ''} name={this.state.codeCopied == 11 ? 'check' : 'copy outline'} />
                                  <p>{this.state.codeCopied == 11 ? ' Copied!' : 'Copy'}</p>
                                </Button>
                              </div>
                              <div className='bash-body'>
                                <p>dotnet add package HtmlAgilityPack</p>
                              </div>
                            </div>
                            <p>Modify <span>Program.cs</span> to extract specific elements:</p>
                            <div className='bash'>
                              <div className='bash-header'>
                                <label>bash</label>
                                <Button onClick={() => this.onClickCopy(12, false)}>
                                  <Icon className={this.state.codeCopied == 12 ? 'check' : ''} name={this.state.codeCopied == 12 ? 'check' : 'copy outline'} />
                                  <p>{this.state.codeCopied == 12 ? ' Copied!' : 'Copy'}</p>
                                </Button>
                              </div>
                              <div className='bash-body'>
                                <p><span className='blue'>using</span> HtmlAgilityPack;</p>
                                <br></br>
                                <p><span className='blue'>static</span> <span className='orange'>string</span> <span className='red'>ExtractContent</span>{parentesisizquierdo}<span className='orange'>string</span> html{parentesisderecho}</p>
                                <p>{llaveizquierda}</p>
                                <p className='one-space'><span className='blue'>var</span> doc = <span className='blue'>new</span> HtmlDocument();</p>
                                <p className='one-space'>doc.LoadHtml(html);</p>
                                <br></br>
                                <p className='one-space'><span className='grey'>// Extract content, e.g., all paragraphs</span></p>
                                <p className='one-space'><span className='blue'>var</span> paragraphs = doc.DocumentNode.SelectNodes{parentesisizquierdo}<span className='green'>"//p"</span>{parentesisderecho};</p>
                                <p className='one-space'><span className='blue'>return</span> <span className='orange'>string</span>.Join{parentesisizquierdo}<span className='green'>"\n"</span>, paragraphs.Select(p ={mayor} p.InnerText.Trim()){parentesisderecho};</p>
                                <p>{llavederecha}</p>
                              </div>
                            </div>
                          </div>
                          <h3>Step 3: Analyzing Content with Ollama</h3>
                          <div className='step'>
                            <p>
                              Now that we have the content, let's pass it to Ollama for analysis.
                              Use Ollama’s capabilities to summarize, analyze sentiment, or answer questions based on the content.
                            </p>
                            <p>Add code to run an Ollama model, as shown in previous articles:</p>
                            <div className='bash'>
                              <div className='bash-header'>
                                <label>csharp</label>
                                <Button onClick={() => this.onClickCopy(13, false)}>
                                  <Icon className={this.state.codeCopied == 13 ? 'check' : ''} name={this.state.codeCopied == 13 ? 'check' : 'copy outline'} />
                                  <p>{this.state.codeCopied == 13 ? ' Copied!' : 'Copy'}</p>
                                </Button>
                              </div>
                              <div className='bash-body'>
                                <p><span className='blue'>using</span> System.Diagnostics;</p>
                                <br></br>
                                <p><span className='blue'>static</span> <span className='orange'>string</span> <span className='red'>AnalyzeContentWithOllama</span>{parentesisizquierdo}<span className='orange'>string</span> content{parentesisderecho}</p>
                                <p>{llaveizquierda}</p>
                                <p className='one-space'><span className='blue'>var</span> process = <span className='blue'>new</span> Process</p>
                                <p className='one-space'>{llaveizquierda}</p>
                                <p className='two-spaces'>StartInfo = <span className='blue'>new</span> ProcessStartInfo</p>
                                <p className='two-spaces'>{llaveizquierda}</p>
                                <p className='three-spaces'>FileName = <span className='green'>"ollama"</span>,</p>
                                <p className='three-spaces'>Arguments = <span className='green'>$"run llama3 'Summarize this: {llaveizquierda}content{llavederecha}'"</span>,</p>
                                <p className='three-spaces'>RedirectStandardOutput = <span className='blue'>true</span>,</p>
                                <p className='three-spaces'>UseShellExecute = <span className='blue'>false</span>,</p>
                                <p className='three-spaces'>CreateNoWindow = <span className='blue'>true</span></p>
                                <p className='two-spaces'>{llavederecha}</p>
                                <p className='one-space'>{llavederecha};</p>
                                <br></br>
                                <p className='one-space'>process.Start();</p>
                                <p className='one-space'><span className='orange'>string</span> result = process.StandardOutput.ReadToEnd();</p>
                                <p className='one-space'>process.WaitForExit();</p>
                                <br></br>
                                <p className='one-space'><span className='blue'>return</span> result;</p>
                                <p>{llavederecha}</p>
                              </div>
                            </div>
                            <p>Call this method after extracting content to analyze it.</p>
                          </div>
                          <h3>Step 4: Testing and Enhancements</h3>
                          <div className='step'>
                            <p>Run your application to see the output, then consider adding more functionality, such as:</p>
                            <ul class="custom-bullets">
                              <li>Setting up multiple endpoints to scrape and analyze different sources.</li>
                              <li>Enhancing data processing for large or structured pages.</li>
                              <li>Using Ollama to extract insights, compare data, or generate summaries across multiple pages.</li>
                            </ul>
                          </div>
                          <h3>Demo Video</h3>
                          <div className='step'>
                            <p>To make the implementation clearer, here is a demonstration video showcasing the execution and results of the application:</p>
                            <ReactPlayer
                              url='../../ollama-scraper.mp4'
                              poster="../../portada3.png"
                              controls={true}
                              width='100%'
                              height='auto'
                              style={{
                                borderRadius: '6px',
                                background: 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)'
                              }}
                              config={{
                                file: {
                                  attributes: {
                                    poster: '../../portada3.png'
                                  }
                                }
                              }}
                            />
                          </div>
                          <h3>Conclusion</h3>
                          <div className='step'>
                            <p>
                              In this article, we’ve built a basic scraper in .NET and integrated Ollama for content analysis.
                              This setup provides a foundation for applications that need to gather and process information automatically,
                              making it a powerful tool for research, market analysis, or content curation.
                            </p>
                          </div>
                          <h3>Source Code</h3>
                          <div className='last-step'>
                            <p>
                              You can find the complete source code for this project on GitHub. Feel free to explore, clone, and modify it for your own use: <a href='https://github.com/coowise/OllamaScraperApp' target='_blank'>GitHub Repository: OllamaScraperApp</a>
                            </p>
                            <p>
                              This repository includes all the files and configurations used in this tutorial, making it easy for you to replicate and extend the application.
                            </p>
                          </div>
                        </div>
                        <div className='footer'>
                          <span>AI Development</span>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
                : (hash == "#bolt.new-vs-v0" ?
                  <>
                    <Helmet>
                      <title>Comparative Analysis: Bolt.new vs. V0</title>
                      <meta name="description" content="When developing modern applications, the tools you choose can significantly impact your workflow and productivity.
                              Both Bolt.new and V0 offer powerful solutions, but they address different aspects of the development process." />
                      <meta name="keywords" content="ollama, .NET, C#, AI, NLP, console application, language model, artificial intelligence" />
                      <meta property="og:title" content="Comparative Analysis: Bolt.new vs. V0" />
                      <meta property="og:description" content="When developing modern applications, the tools you choose can significantly impact your workflow and productivity.
                              Both Bolt.new and V0 offer powerful solutions, but they address different aspects of the development process." />
                      <meta property="og:type" content="article" />
                      <meta property="article:published_time" content="2024-12-02" />
                    </Helmet>
                    <section class="article-section">
                      <div className='article-container'>
                        <div className='article-header'>
                          <a href='/blog'>
                            <div className='back-button-container'>
                              <img src={arrowLeft} />
                              <label>Back to Articles</label>
                            </div>
                          </a>
                        </div>
                        <div className='article'>
                          <h1>Comparative Analysis: Bolt.new vs. V0</h1>
                          <div className='author-date-container'>
                            {/* <div className='general-container'>
                            <img src={user} />
                            <label>Jonathan Confenti</label>
                          </div> */}
                            <div className='general-container'>
                              <img src={calendar} />
                              <label>December 2, 2024 • 8 min read</label>
                            </div>
                          </div>
                          <div className='article-div'>
                            <p>
                              When developing modern applications, the tools you choose can significantly impact your workflow and productivity.
                              Both Bolt.new and V0 offer powerful solutions, but they address different aspects of the development process.
                            </p>
                            <p>
                              In this article, we’ll dive into their features, use cases, and best applications, helping you decide which tool aligns best with your project goals and team needs.
                            </p>
                            <h3>Overview</h3>
                            <div className='step'>
                              <p>
                                <span className='bold'>Bolt.new</span>is designed for rapid project scaffolding, enabling developers to quickly set up applications with prebuilt architectures for frameworks like .NET or Node.js.
                              </p>
                              <p className='mb-15'>Visit <a href='https://bolt.new/'>Bolt.new</a> to start creating structured projects.</p>
                              <p>
                                <span className='bold'>V0</span>is an AI-driven assistant that simplifies web development with features like UI generation, workflow automation, and in-depth technical guidance.
                              </p>
                              <p>Access the platform at <a href='https://v0.dev/'>v0.dev</a> to explore its powerful capabilities.</p>
                            </div>
                            <h3>Feature Comparison</h3>
                            <div className='step'>
                              <div className='privacy-policy-table-container blog'>
                                <table className="privacy-policy-table blog">
                                  <thead>
                                    <tr className='tr-head blog'>
                                      <td className='thead-td-table'>
                                        <p className='thead-text'>{this.props.language == "ES" ? "Feature" : "Feature"}</p>
                                      </td>
                                      <td className='thead-td-table'>
                                        <p className='thead-text'>{this.props.language == "ES" ? "Bolt.new" : "Bolt.new"}</p>
                                      </td>
                                      <td className='thead-td-table'>
                                        <p className='thead-text'>{this.props.language == "ES" ? "V0" : "V0"}</p>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Ease of Use</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Developer-focused</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Intuitive, even for non-tech users</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Framework Support</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Broad backend compatibility</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Focus on modern web technologies</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Automation Capabilities</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Limited to project setup</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>High, with continuous task automation</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Cost</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Often free</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Subscription-based</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Collaboration</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Geared toward technical users</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Supports non-technical teams</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Code Scalability</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Deep customization</p>
                                      </td>
                                      <td className='tbody-td-table'>
                                        <p className='tbody-text'>Limited to generated output</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className='blog-table'>
                                <div className='blog-table-row'>
                                  <div className='blog-table-row-header'>
                                    <h3>Ease of Use</h3>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>Bolt.new</p>
                                    <p className='description'>Developer-focused</p>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>V0</p>
                                    <p className='description'>Intuitive, even for non-tech users</p>
                                  </div>
                                </div>
                                <div className='blog-table-row'>
                                  <div className='blog-table-row-header'>
                                    <h3>Framework Support</h3>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>Bolt.new</p>
                                    <p className='description'>Broad backend compatibility</p>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>V0</p>
                                    <p className='description'>Focus on modern web technologies</p>
                                  </div>
                                </div>
                                <div className='blog-table-row'>
                                  <div className='blog-table-row-header'>
                                    <h3>Automation Capabilities</h3>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>Bolt.new</p>
                                    <p className='description'>Limited to project setup</p>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>V0</p>
                                    <p className='description'>High, with continuous task automation</p>
                                  </div>
                                </div>
                                <div className='blog-table-row'>
                                  <div className='blog-table-row-header'>
                                    <h3>Cost</h3>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>Bolt.new</p>
                                    <p className='description'>No subscription fee</p>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>V0</p>
                                    <p className='description'>Subscription-based</p>
                                  </div>
                                </div>
                                <div className='blog-table-row'>
                                  <div className='blog-table-row-header'>
                                    <h3>Collaboration</h3>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>Bolt.new</p>
                                    <p className='description'>Geared toward technical users</p>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>V0</p>
                                    <p className='description'>Supports non-technical teams</p>
                                  </div>
                                </div>
                                <div className='blog-table-row'>
                                  <div className='blog-table-row-header'>
                                    <h3>Code Scalability</h3>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>Bolt.new</p>
                                    <p className='description'>Deep customization</p>
                                  </div>
                                  <div className='blog-table-column'>
                                    <p className='tool'>V0</p>
                                    <p className='description'>Limited to generated output</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h3>Use Cases</h3>
                            <div className='step'>
                              <h4 className='article-subtitle'>Bolt.new</h4>
                              <ul class="custom-bullets">
                                <li>Rapid MVP Development: Quickly create prototypes or test ideas in minutes.</li>
                                <li>Business Automation: Build tools for managing customer queries or sales tracking without complex coding.</li>
                                <li>Custom Dashboards and CRMs: Highly customizable with an intuitive drag-and-drop interface.</li>
                                <li>Privacy-Controlled Development: Run locally with Ollama integration, ensuring full control over your environment.</li>
                              </ul>
                              <h4 className='article-subtitle'>V0</h4>
                              <ul class="custom-bullets">
                                <li>Frontend Prototyping: Generate React, Vue, or Svelte-based UIs quickly.</li>
                                <li>Workflow Optimization: Automate repetitive tasks or streamline collaborative workflows.</li>
                                <li>Collaborative Design: Empower non-technical team members to bring ideas to life.</li>
                                <li>Code Debugging and Strategy: Use AI to guide development and planning tasks.</li>
                              </ul>
                            </div>
                            <h3>Conclusion</h3>
                            <div className='last-step'>
                              <p>
                                Choose Bolt.New if you need a no-code platform for building apps, automating tasks, or prototyping MVPs efficiently. 
                                Opt for VO if you’re focused on frontend development, want to leverage AI for web workflows, 
                                or need a collaborative environment for cross-functional teams.
                              </p>
                            </div>
                          </div>
                          <div className='footer'>
                            <span>{hash == "#bolt.new-vs-v0" ? "Web Development" : "AI Development"}</span>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                  : null
                )
              ))
        }
        <Footer
          language={this.props.language}
          currentSection={this.props.currentSection}
          changeCurrentSection={this.props.changeCurrentSection}
          cookieUp={this.props.cookieUp}
        />
      </div>
    );
  }
}

const mapStateToProps = (value) => {
  return {
    language: value.general.language,

    currentSection: value.general.currentSection,

    cookieUp: value.general.cookieUp
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogArticle);