import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import cross from '../images/cross.svg';
import rocket from '../images/rocket.svg';
import whiteRocket from '../images/white-rocket.svg';

class DiscoverBlogModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReactModal 
                onRequestClose={() => this.props.changeDiscoverBlogModalFlag()} 
                shouldCloseOnOverlayClick={true} 
                isOpen={this.props.discoverBlogModalFlag} 
                className={"generic-modal container-modal discover-blog animate__animated animate__fadeInDown animate__faster"}
                overlayClassName={"discover-blog-card-overlay"}
            >
                <div className='discover-blog-card'>
                    <img className= "closeModal" src={cross} onClick={() => this.props.changeDiscoverBlogModalFlag()}/>
                    <div className='header-image'>
                        <img src={rocket}/>
                    </div>
                    <h1>Discover Our New Tech Blog!</h1>
                    <p>Explore in-depth tutorials, industry insights, and the latest in technology. Join our community of developers and tech enthusiasts!</p>
                    <a href={"/blog"} target='blank'>
                        <button className='explore' onClick={() => this.props.changeDiscoverBlogModalFlag()}>Explore Now <img src={whiteRocket}/></button>
                    </a>
                    <button className='later' onClick={() => this.props.changeDiscoverBlogModalFlag()}>Maybe later</button>
                </div>
            </ReactModal>
        );
    }
}

DiscoverBlogModal.propTypes = {
    discoverBlogModalFlag: PropTypes.bool.isRequired,
    changeDiscoverBlogModalFlag: PropTypes.func.isRequired
};

export default (DiscoverBlogModal);