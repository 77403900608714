import React, { Component } from 'react';
import Footer from '../Footer';
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
  changeCurrentSection,
  changeSubscribeBlogStates
} from "../../actions/generalActions";
import magnifyingGlass from '../images/magnifying_glass.svg';
import arrowRight from '../images/arrow-right.svg';
import ollama from '../images/ollama.png';
import react from '../images/react-card.jpeg';
import { Helmet } from 'react-helmet';
import { Snackbar } from '@material/react-snackbar';
import { Icon } from "semantic-ui-react";

const categories = [
  "Web Development",
  "AI Development",
  "AI Development",
  "AI Development"
];

const titles = [
  "Bolt.new vs. V0",
  "How to install and run ollama locally in .NET",
  "How to build a chat app using ollama",
  "Ollama + .NET Scraper"
];

const descriptions = [
  "Discover the key differences between Bolt.new and V0, two powerful tools for web development. Find which tool fits your development needs.",
  "Learn how to integrate ollama into your .NET console application for powerful language model capabilities.",
  "Combine the power of ollama with web technologies to create an intelligent chat application from scratch.",
  "Learn how to build a .NET-based web scraper and integrate it with Ollama for powerful content analysis."
];

class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: '',
      newsletterModalFlag: false,
      filteredResults: [true, true, true, true],
      page: 1,
      total: 2
    }

    this.changeSection = this.changeSection.bind(this);
    this.changeCategoryButton = this.changeCategoryButton.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.filterCards = this.filterCards.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeArrowPage = this.changeArrowPage.bind(this);
  }

  changeSection() {
    this.props.changeCurrentSection("Blog");
    if (this.props.cookieUp)
      ReactGA4.send({ hitType: "pageview", title: "Blog", page: '/Blog' });
  }

  changeCategoryButton(input) {
    let filteredResults = this.filterCards(input);
    this.setState({ input: input, filteredResults: filteredResults });
  }

  onChangeInput(e) {
    let filteredResults = this.filterCards(e.target.value);
    this.setState({ input: e.target.value, filteredResults: filteredResults });
  }

  changeNewsletterModalFlag() {
    let newsletterModalFlag = JSON.parse(window.sessionStorage.getItem("newsletterModalFlag"));
    window.sessionStorage.setItem("newsletterModalFlag", !newsletterModalFlag);
    this.setState({ newsletterModalFlag: !this.state.newsletterModalFlag });
  }

  filterCards(input) {
    // Convertir el input a minúsculas
    const inputLower = input.toLowerCase();

    // Array de resultados true/false
    const results = titles.map((title, index) => {
      // Comprobar si el input está en el título, categoría o descripción (todo en minúsculas)
      return (
        title.toLowerCase().includes(inputLower) ||
        categories[index].toLowerCase().includes(inputLower) ||
        descriptions[index].toLowerCase().includes(inputLower)
      );
    });

    return results;
  }

  changePage(page) {
    let element = document.getElementById("blog-global-screen");
    if (element != null)
      element.scrollIntoView({ behavior: "smooth" });

    setTimeout(() => {
      this.setState({ page: page });
    }, this.state.page == 1 ? 350 : 0); // Retraso de 1 segundo (1000 ms)
  }

  changeArrowPage(arrow) {
    let element = document.getElementById("blog-global-screen");
    if (element != null)
      element.scrollIntoView({ behavior: "smooth" });

    if (arrow === "left")
      setTimeout(() => {
        this.setState({ page: this.state.page == 1 ? this.state.total : this.state.page - 1 });
      }, this.state.page == 1 ? 350 : 0);
    else if (arrow === "right")
      setTimeout(() => {
        this.setState({ page: this.state.page == this.state.total ? 1 : this.state.page + 1 });
      }, this.state.page == 1 ? 350 : 0);
  }

  render() {
    return (
      <div id='blog-global-screen' className='blog-global-screen' onMouseOver={this.props.currentSection != "Blog" ? this.changeSection : () => { }}>
        <Helmet>
          <title>Blog - AI and Web Development Articles</title>
          <meta name="description" content="Explore articles about AI Development, Web Development, and practical tutorials on implementing Ollama, React, and modern web technologies." />
          <meta name="keywords" content="AI Development, Web Development, Ollama, React, Chat Applications, Tutorials" />

          {/* OpenGraph tags for social sharing */}
          <meta property="og:title" content="Blog - AI and Web Development Articles" />
          <meta property="og:description" content="Explore articles about AI Development, Web Development, and practical tutorials on implementing Ollama, React, and modern web technologies." />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={ollama} />

          {/* Additional meta tags */}
          <link rel="canonical" href={window.location.origin + "/blog"} />
        </Helmet>
        <section class="blog-section">
          <div className='blog-container'>
            <div className='blog-header'>
              <div className='blog-search-bar-container'>
                <input type="text" placeholder="Search articles..." className="blog-search-bar" onChange={this.onChangeInput} value={this.state.input}></input>
                <img className="magnifying-glass" src={magnifyingGlass}></img>
              </div>
              <div className='blog-category-buttons-container'>
                <button className='category-button' onClick={() => this.changeCategoryButton("AI Development")}>AI Development</button>
                <button className='category-button' onClick={() => this.changeCategoryButton("Web Development")}>Web Development</button>
              </div>
            </div>
            <div className='blog-cards-section'>
              <h1 id='latest-articles'>Latest Articles</h1>
              <div className='blog-cards-container'>
                {
                  (this.state.filteredResults[0] && ((window.innerWidth > 767) || (window.innerWidth <= 767 && this.state.page == 1))) ?
                    <div className='blog-card'>
                      <img className="thumbnails ollama" src={react} />
                      <div className='blog-card-body-header'>
                        <label>Web Development</label>
                      </div>
                      <h3>Bolt.new vs. V0</h3>
                      <p className='card-paragraph'>Discover the key differences between Bolt.new and V0, two powerful tools for web development. Find which tool fits your development needs.</p>
                      <div className='blog-card-footer'>
                        <label>2024-12-02</label>
                        <a href={window.location.origin + "/article#bolt.new-vs-v0"}>
                          <div className='footer-container'>
                            <label>Read more</label>
                            <img src={arrowRight}></img>
                          </div>
                        </a>
                      </div>
                    </div>
                    : null
                }
                {
                  (this.state.filteredResults[1] && ((window.innerWidth > 767) || (window.innerWidth <= 767 && this.state.page == 1))) ?
                    <div className='blog-card'>
                      <img className="thumbnails ollama" src={ollama} />
                      <div className='blog-card-body-header'>
                        <label>AI Development</label>
                      </div>
                      <h3>Getting started with Ollama in .NET</h3>
                      <p className='card-paragraph'>Learn how to integrate ollama into your .NET console application for powerful language model capabilities.</p>
                      <div className='blog-card-footer'>
                        <label>2024-11-19</label>
                        <a href={window.location.origin + "/article#install-and-run-ollama-net"}>
                          <div className='footer-container'>
                            <label>Read more</label>
                            <img src={arrowRight}></img>
                          </div>
                        </a>
                      </div>

                    </div>
                    : null
                }
                {
                  (this.state.filteredResults[2] && ((window.innerWidth > 767) || (window.innerWidth <= 767 && this.state.page == 1))) ?
                    <div className='blog-card'>
                      <img className="thumbnails ollama" src={ollama} />
                      <div className='blog-card-body-header'>
                        <label>AI Development</label>
                      </div>
                      <h3>How to build a chat app using ollama</h3>
                      <p className='card-paragraph'>Combine the power of ollama with web technologies to create an intelligent chat application from scratch.</p>
                      <div className='blog-card-footer'>
                        <label>2024-11-19</label>
                        <a href={window.location.origin + "/article#build-chat-app-using-ollama"}>
                          <div className='footer-container'>
                            <label>Read more</label>
                            <img src={arrowRight}></img>
                          </div>
                        </a>
                      </div>
                    </div>
                    : null
                }
                {
                  (this.state.filteredResults[3] && ((window.innerWidth > 767) || (window.innerWidth <= 767 && this.state.page == 2))) ?
                    <div className='blog-card'>
                      <img className="thumbnails ollama" src={ollama} />
                      <div className='blog-card-body-header'>
                        <label>AI Development</label>
                      </div>
                      <h3>Ollama + .NET Scraper</h3>
                      <p className='card-paragraph'>Learn how to build a .NET-based web scraper and integrate it with Ollama for powerful content analysis.</p>
                      <div className='blog-card-footer'>
                        <label>2024-11-19</label>
                        <a href={window.location.origin + "/article#extract-and-analyze-web-content-using-ollama"}>
                          <div className='footer-container'>
                            <label>Read more</label>
                            <img src={arrowRight}></img>
                          </div>
                        </a>
                      </div>
                    </div>
                    : null
                }
              </div>
            </div>
            <div className='blog-footer'>
              <Icon onClick={() => this.changeArrowPage("left")} name={'angle left'} />
              <button onClick={() => this.changePage(1)} className={this.state.page == 1 ? 'selected' : ""}>1</button>
              <button onClick={() => this.changePage(2)} className={this.state.page == 2 ? 'selected' : ""}>2</button>
              <Icon onClick={() => this.changeArrowPage("right")} name={'angle right'} />
            </div>
          </div>
        </section>
        <Footer
          language={this.props.language}
          currentSection={this.props.currentSection}
          changeCurrentSection={this.props.changeCurrentSection}
          cookieUp={this.props.cookieUp}
        />
        {
          this.props.subscribeBlogError !== null || this.props.fetchedSubscribeBlog
            ?
            <Snackbar
              className={this.props.fetchedSubscribeBlog ? "toastSuccess" : "errorToast"}
              message=
              {
                this.props.fetchedSubscribeBlog
                  ? this.props.language == "ES"
                    ? "Se ha suscrito con exito."
                    : "You have successfully subscribed"
                  : this.props.language == "ES"
                    ? "Ha ocurrido un error. Intente nuevamente."
                    : "An error has occurred. Try again."
              }
              actionText="X"
              onClose=
              {
                this.props.fetchedSubscribeBlog
                  ? () => this.props.changeSubscribeBlogStates()
                  : () => { }
              }
            />
            : null
        }
      </div>
    );
  }
}

const mapStateToProps = (value) => {
  return {
    language: value.general.language,

    currentSection: value.general.currentSection,

    cookieUp: value.general.cookieUp,

    fetchedSubscribeBlog: value.general.fetchedSubscribeBlog,
    subscribeBlogError: value.general.subscribeBlogError
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection)),
    changeSubscribeBlogStates: () => dispatch(changeSubscribeBlogStates())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);