import React, { Component } from 'react';
import Footer from './Footer';
import Contact from './Contact';
import Certifications from './Certifications';
import Solutions from './Solutions';
import Services from './Services';
import Header from './Header';
import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';
import { connect } from "react-redux";
import {
    changeCreateProspectStates,
    changeCurrentSection,
    acceptCookies
} from "../actions/generalActions";
import {
    CookieContextProvider,
    CookieBanner,
    CookieDialog,
    NonEssencialCookies,
    EssencialCookies
} from '@coowise/cookies-preferences-package';
import DiscoverBlogModal from './modals/DiscoverBlogModal';

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorModalFlag: false,
            discoverBlogModalFlag: true
        }

        this.changeErrorModalFlag = this.changeErrorModalFlag.bind(this);
        this.changeDiscoverBlogModalFlag = this.changeDiscoverBlogModalFlag.bind(this);
    }

    changeErrorModalFlag() {
        this.setState({ errorModalFlag: !this.state.errorModalFlag });
    }

    changeDiscoverBlogModalFlag(){
        let discoverBlogModalFlag = JSON.parse(window.sessionStorage.getItem("discoverBlogModalFlag"));
        window.sessionStorage.setItem("discoverBlogModalFlag", !discoverBlogModalFlag);
        this.setState({ discoverBlogModalFlag: !this.state.discoverBlogModalFlag });
    }

    render() {
        let discoverBlogModalFlag = window.sessionStorage.getItem("discoverBlogModalFlag");

        if(discoverBlogModalFlag == null){
            discoverBlogModalFlag = true;
            window.sessionStorage.setItem("discoverBlogModalFlag", true);
          }
          else
            discoverBlogModalFlag = JSON.parse(discoverBlogModalFlag);

        return (
            <>
                <Header
                    language={this.props.language}
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <Services
                    language={this.props.language}
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <Solutions
                    language={this.props.language}
                    changeErrorModalFlag={this.changeErrorModalFlag}
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <Certifications
                    language={this.props.language}
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <Contact
                    language={this.props.language}
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <Footer
                    language={this.props.language}
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <DiscoverBlogModal
                    discoverBlogModalFlag={discoverBlogModalFlag}
                    changeDiscoverBlogModalFlag={this.changeDiscoverBlogModalFlag}
                />

                <CookieContextProvider source="web-coowise">
                    <CookieBanner
                        title={this.props.language == "ES" ? "Tu privacidad" : "Your privacy"}
                        message=
                        {
                            this.props.language == "ES"
                                ? "Utilizamos cookies propias y de terceros para mejorar nuestros servicios y mostrarte publicidad relacionada con tus preferencias, mediante el análisis de tus hábitos de navegación. Pulsa el botón ACEPTAR para confirmar que has leído y aceptado la información presentada."
                                : "We use our own and third-party cookies to improve our services and show you advertising related to your preferences, by analyzing your browsing habits. Click on ACCEPT to confirm that you have read and accepted the information presented."
                        }
                        policyText=
                        {
                            this.props.language == "ES"
                                ? "Para saber más, lea nuestra política de cookies actualizada en el enlace"
                                : "To find out more, read our updated cookies policy in the link"
                        }
                        policyLinkText=
                        {
                            this.props.language == "ES"
                                ? "Política de Cookies"
                                : "Cookies Policy"
                        }
                        policyLink={"/cookies-policy"}
                        acceptButtonText={this.props.language == "ES" ? "ACEPTO" : "ACCEPT"}
                        manageConsentText={this.props.language == "ES" ? "Administrar preferencias" : "Manage Preferences"}
                        onAccept={this.props.acceptCookies}
                    >
                        <CookieDialog
                            title={this.props.language == "ES" ? "Tus opciones de privacidad" : "Your privacy options"}
                            message={this.props.language == "ES" ? "Por favor revise y administre su configuración de privacidad a continuación" : "Please review and manage your privacy settings belows"}
                            confirmText={this.props.language == "ES" ? "Guardar preferencias" : "Save Preferences"}
                        >
                            <NonEssencialCookies
                                title={this.props.language == "ES" ? "No esenciales" : "Non-essential"}
                                message=
                                {
                                    this.props.language == "ES"
                                        ? "Las siguientes cookies NO son estrictamente necesarias para permitir el acceso y el uso de nuestra Plataforma y se pueden desactivar. "
                                        : "The following cookies are NOT strictly necessary for enabling access and use of our Platform and can be disabled. "
                                }
                                policyText=
                                {
                                    this.props.language == "ES" 
                                    ? "Para obtener más información sobre cómo funcionan estas cookies, consulte nuestra "
                                    : "For more information on how these cookies work, please review our "
                                }
                                policyLinkText={this.props.language == "ES"  ? "Política de privacidad" : "Privacy Policy"}
                                policyLink="/cookies-policy"
                                messages=
                                {
                                    [
                                        this.props.language == "ES" 
                                            ? "Al marcar esta casilla, doy mi consentimiento a Coowise (CoowiseIT OÜ), sus afiliados y provedores de servicios para que procesen mis datos personales para los propositos descriptos en la política de privacidad. Entiendo que puedo retirar mi concentimiento en cualquier momento."
                                            : "By checking this box, I consent to Coowise (CoowiseIT OÜ), its affiliates, and service providers processing my personal data for the purposes described in the Privacy Policy. I understand that I can withdraw my consent at any time."
                                    ]
                                }
                            />
                            <EssencialCookies
                                title={this.props.language == "ES" ? "Cookies esenciales (requeridas)" : "Essential cookies (required)"}
                                message=
                                {
                                    this.props.language == "ES" 
                                    ? "Estas cookies son estrictamente necesarias para permitir el acceso y uso de nuestra Plataforma. Proporcionan la funcionalidad necesaria para garantizar el rendimiento, la seguridad y la funcionalidad adecuados de nuestra Plataforma. No se pueden desactivar."
                                    : "These cookies are strictly necessary to enable access and use of our Platform. They provide necessary functionality to ensure the proper performance, security and functionality of our Platform. These cookies cannot be disabled."
                                }
                                policyText=""
                                policyLinkText=""
                                policyLink=""
                            />
                        </CookieDialog>
                    </CookieBanner>
                </CookieContextProvider>

                {
                    this.state.errorModalFlag == true
                        ?
                        <Snackbar
                            className="errorToast"
                            message=
                            {
                                this.props.language == "ES"
                                    ? "Ha ocurrido un error. Por favor refresque la pagina y vuelvo a intentarlo."
                                    : "An error has occurred. Please refresh the page and try it again."
                            }
                            actionText="X"
                            onClose={() => this.changeErrorModalFlag()}
                        />
                        : null
                }

                {
                    this.props.createProspectError !== null || this.props.fetchedCreateProspect
                        ?
                        <Snackbar
                            className={this.props.fetchedCreateProspect ? "toastSuccess" : "errorToast"}
                            message=
                            {
                                this.props.fetchedCreateProspect
                                    ? this.props.language == "ES"
                                        ? "Su mensaje fue enviado con éxito."
                                        : "Your message was successfully sent."
                                    : this.props.language == "ES"
                                        ? "Ha ocurrido un error. Intente nuevamente."
                                        : "An error has occurred. Try again."
                            }
                            actionText="X"
                            onClose=
                            {
                                this.props.fetchedCreateProspect
                                    ? () => this.props.changeCreateProspectStates()
                                    : () => { }
                            }
                        />
                        : null
                }
            </>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        language: value.general.language,

        createProspectError: value.general.createProspectError,
        fetchedCreateProspect: value.general.fetchedCreateProspect,

        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCreateProspectStates: () => dispatch(changeCreateProspectStates()),
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection)),
        acceptCookies: () => dispatch(acceptCookies())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);